import type { ApiLookbooksSectionsUpdatePosition } from "@endearhq/service-schema/lib/Models.schema";
import type { Observable, Subject } from "rxjs";
import { from, of } from "rxjs";
import { catchError, mergeMap, retryWhen } from "rxjs/operators";

import { apiUpdateLookbookSectionPosition } from "../services/api/lookbooks";
import { reportError } from "../services/error-reporter";
import {
  AuthenticationError,
  CodeError,
  ClientError,
  ErrorCode,
  isNetworkError,
  toError,
} from "../services/errors";
import { retryOnServiceUnavailable } from "./retryStrategy";
import type { Res } from "./types";
import { errRes, okRes } from "./types";

export interface Payload {
  body: ApiLookbooksSectionsUpdatePosition["body"];
}

export type Result = Res<{
  body: ApiLookbooksSectionsUpdatePosition["body"];
  response: ApiLookbooksSectionsUpdatePosition["response"]["200"];
}>;

export type Name = "updateLookbookSectionPosition";

export const make = (source$: Subject<Payload>): Observable<Result> =>
  source$.pipe(
    mergeMap(({ body }) => of({ body })),
    mergeMap((req) =>
      from(Promise.resolve(req)).pipe(
        mergeMap(async ({ body }) => apiUpdateLookbookSectionPosition(body)),
        mergeMap((res): Observable<Result> => {
          switch (res.status) {
            case 200: {
              return of(okRes({ body: req.body, response: res.data }));
            }
            case 401: {
              throw new AuthenticationError(res);
            }
            case 422: {
              switch (res.data.message) {
                case "AlreadyDeleted": {
                  throw new ClientError({
                    heading: "Updated Failed",
                    body: "Someone else has edited this lookbook",
                  });
                }
                default: {
                  throw new CodeError({
                    code: ErrorCode.UnexpectedStatus,
                    payload: res,
                  });
                }
              }
            }
            case 403: {
              throw new CodeError({
                code: ErrorCode.PermissionDenied,
                payload: res,
              });
            }
            default: {
              throw new CodeError({
                code: ErrorCode.UnexpectedStatus,
                payload: res,
              });
            }
          }
        }),
        retryWhen(retryOnServiceUnavailable()),
        catchError((err) => {
          const error = isNetworkError(err)
            ? new CodeError({ code: ErrorCode.Offline })
            : toError(err);

          reportError(error);

          return of(errRes(error));
        }),
      ),
    ),
  );
