import { setUser, captureException, init, withScope } from "@sentry/browser";

import { RELEASE, DOPPLER_CONFIG, SENTRY_DSN } from "../conf";
import type { AppError } from "./errors";

init({
  enabled: typeof SENTRY_DSN === "string",
  environment: DOPPLER_CONFIG,
  dsn: SENTRY_DSN,
  release: RELEASE,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Loading chunk",
    "@derivedConstructor",
  ],
});

export { setUser, captureException, withScope };

export const reportBoundaryError = (error: unknown, errorInfo: unknown) => {
  withScope((scope) => {
    if (typeof errorInfo === "object" && errorInfo !== null) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key as keyof typeof errorInfo]);
      });
    }

    captureException(error);
  });
};

export class ErrorReporter extends EventTarget {}

export const errorReporter = new ErrorReporter();

export function reportError(error: AppError) {
  errorReporter.dispatchEvent(
    new CustomEvent("appError", { detail: { error } }),
  );
}
