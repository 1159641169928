/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AxiosError } from "axios";

import type { ApiFailureResponse } from "./api/utils";

export class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class InvalidAuthError extends ExtendableError {}

export class InvalidParamError extends ExtendableError {
  constructor(param: string, value: any) {
    super(`${param}: ${value}`);
  }
}

interface AppErrorProps {
  heading: string;
  body: string;
  payload?: any;
}

export type AppError = Error & AppErrorProps;

export function isAppError(val: any): val is AppError {
  return val?.heading && val.body && val instanceof Error;
}

export enum ErrorCode {
  UnexpectedStatus = "UnexpectedStatus",
  MissingCredentials = "MissingCredentials",
  Offline = "Offline",
  PermissionDenied = "PermissionDenied",
  Timeout = "Timeout",
}

export const errorCodeMessage: {
  [K in ErrorCode]: {
    heading: string;
    body: string;
    capture: boolean;
  };
} = {
  [ErrorCode.UnexpectedStatus]: {
    heading: "Oops!",
    body: "Something went wrong. Please try again.",
    capture: true,
  },
  [ErrorCode.MissingCredentials]: {
    heading: "Missing Credentials",
    body: "You must access the application from you Shopify admin",
    capture: false,
  },
  [ErrorCode.Offline]: {
    heading: "Offline",
    body: "It looks like you are not connected to the internet. Please try again after you connect.",
    capture: false,
  },
  [ErrorCode.Timeout]: {
    heading: "Timeout",
    body: "The request timed out.",
    capture: false,
  },
  [ErrorCode.PermissionDenied]: {
    heading: "Permission Denied",
    body: "You do not have permission to complete this action.",
    capture: true,
  },
};

export function isNetworkError(error: any): error is Error {
  if (error instanceof Error && error.message.includes("Network Error")) {
    return true;
  }

  const { response } = error as AxiosError;

  return response ? response.status === 504 : false;
}

export function toError(
  error: any,
  { heading, body }: AppErrorProps = {
    heading: "Oops!",
    body: "Something went wrong. Please try again.",
  },
): AppError {
  if (error instanceof ClientError || error instanceof CodeError) {
    return error;
  }

  if (error instanceof Error) {
    const err = error as AppError;
    err.heading = heading;
    err.body = body;

    return err;
  }

  return new ClientError({ heading, body, payload: error });
}

export class ClientError extends ExtendableError {
  capture = false;
  heading: string;
  body: string;
  payload?: any;

  constructor({ heading, body, payload }: AppErrorProps & { payload?: any }) {
    super(body);
    this.heading = heading;
    this.body = body;
    this.payload = payload;
  }
}

export class AuthenticationError extends ExtendableError {
  hint?: "UserRequired";
  constructor(response: ApiFailureResponse) {
    super("Request failed with status code 401");
    this.hint = response.data.data?.hint;
  }
}

export class CodeError extends ExtendableError {
  heading: string;
  body: string;
  code: ErrorCode;
  capture: boolean;
  payload?: any;

  constructor({
    code,
    payload,
  }: {
    code: ErrorCode;
    payload?: Record<string, any>;
  }) {
    const message = [
      code,
      typeof payload?.status === "number" ? payload.status : undefined,
      typeof payload?.data === "string"
        ? payload.data
        : typeof payload?.data?.message === "string"
        ? payload.data.message
        : undefined,
    ]
      .filter(Boolean)
      .join(": ");

    super(message);
    this.heading = errorCodeMessage[code].heading;
    this.body = errorCodeMessage[code].body;
    this.capture = errorCodeMessage[code].capture;
    this.code = code;
    this.payload =
      typeof payload?.status === "number"
        ? {
            status: payload.status,
            headers: payload.headers,
            body: payload.data,
          }
        : payload;
  }
}

export class PermissionRequiredError extends ExtendableError {
  constructor(
    _type?: "admin" | "messaging" | "clients" | "lookbooks" | "tasks" | "sales",
  ) {
    super("You do not have permission to view this page.");
  }
}
