/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AxiosPromise, AxiosResponse } from "axios";

export type Response<T> = Omit<AxiosResponse<T>, "status">;

export type FailureStatus = 504 | 500 | 401 | 403 | 422 | 400 | 404;

export type ApiOkResponse<T> = Response<T> & {
  status: 200 | 204;
};

export interface Failure {
  error: string;
  message: string;
  statusCode: FailureStatus;
  data?: {
    [k: string]: any;
  };
}

export type ApiFailureResponse = Response<Failure> & {
  status: FailureStatus;
};

export type ApiResponse<T> = ApiOkResponse<T> | ApiFailureResponse;

export const request = async <T>(req: () => AxiosPromise<T>) => {
  return req().then((res) => res as ApiResponse<T>);
};

export const getUniqueIDForError = () => {
  return `_${Math.random().toString(36).substr(2, 9)}`;
};
