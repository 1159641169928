/* eslint-disable @typescript-eslint/no-explicit-any */

import { Observable, Subject, Subscription } from "rxjs";

export { Observable, Subject, Subscription };

export type Epics<Keys extends string> = {
  [K in Keys]: {
    action: Subject<any>;
    cancel: Subject<void>;
    observer: Observable<any>;
  };
};

export type ResultOf<T> = T extends Observable<infer A> ? A : never;

export interface Subscriptions<K extends string> {
  register: [
    K,
    (source: Subject<any>) => Observable<any>,
    (result: any) => void,
  ][];
  current: [K, Subscription][];
}

export function unsubscribe<K extends string>(
  subscriptions: Subscriptions<K>,
): void {
  subscriptions.current.forEach(([, subscription]) => {
    subscription.unsubscribe();
  });
}

export function subscribe<K extends string>(
  sources: { [Key in K]: Subject<any> },
  subscriptions: Subscriptions<K>,
): void {
  unsubscribe(subscriptions);

  subscriptions.current = subscriptions.register.map(
    ([key, make, onComplete]): [K, Subscription] => [
      key,
      make(sources[key]).subscribe(onComplete),
    ],
  );
}
