import type { ApiAccountsSenderDomainsCreate } from "@endearhq/service-schema/lib/Models.schema";
import type { Observable, Subject } from "rxjs";
import { from, of } from "rxjs";
import { catchError, mergeMap, retryWhen } from "rxjs/operators";

import { apiCreateSenderDomain } from "../services/api/accounts";
import { reportError } from "../services/error-reporter";
import {
  AuthenticationError,
  CodeError,
  ErrorCode,
  isNetworkError,
  toError,
} from "../services/errors";
import { retryOnServiceUnavailable } from "./retryStrategy";
import type { Res } from "./types";
import { errRes, okRes } from "./types";

export interface Payload {
  body: ApiAccountsSenderDomainsCreate["body"];
}

export type Result = Res<
  | {
      ok: true;
      body: ApiAccountsSenderDomainsCreate["body"];
      response: ApiAccountsSenderDomainsCreate["response"]["200"];
    }
  | {
      ok: false;
      body: ApiAccountsSenderDomainsCreate["body"];
      error: "DomainAlreadyExists";
    }
>;

export type Name = "createSenderDomain";

export const make = (source$: Subject<Payload>): Observable<Result> =>
  source$.pipe(
    mergeMap(({ body }) => of({ body })),
    mergeMap((req) =>
      from(Promise.resolve(req)).pipe(
        mergeMap(async ({ body }) => apiCreateSenderDomain(body)),
        mergeMap((res): Observable<Result> => {
          switch (res.status) {
            case 200: {
              return of(
                okRes({
                  ok: true,
                  body: req.body,
                  response: res.data,
                }),
              );
            }
            case 422: {
              switch (res.data.message) {
                case "DomainAlreadyExists": {
                  return of(
                    okRes({
                      ok: false,
                      body: req.body,
                      error: "DomainAlreadyExists",
                    }),
                  );
                }
                default: {
                  throw new CodeError({
                    code: ErrorCode.UnexpectedStatus,
                    payload: res,
                  });
                }
              }
            }
            case 401: {
              throw new AuthenticationError(res);
            }
            case 403: {
              throw new CodeError({
                code: ErrorCode.PermissionDenied,
                payload: res,
              });
            }
            default: {
              throw new CodeError({
                code: ErrorCode.UnexpectedStatus,
                payload: res,
              });
            }
          }
        }),
        retryWhen(retryOnServiceUnavailable()),
        catchError((err) => {
          const error = isNetworkError(err)
            ? new CodeError({ code: ErrorCode.Offline })
            : toError(err);

          reportError(error);

          return of(errRes(error));
        }),
      ),
    ),
  );
