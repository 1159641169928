import type { Route } from "@endearhq/service-router";
import { buildUrl as _buildUrl } from "@endearhq/service-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { Params } from "react-router";
import { useParams, useNavigate as _useNavigate } from "react-router";
import type { NavigateOptions } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export function buildUrl<T extends Route>(
  urlProps: T,
  location?: Partial<Location>,
): string {
  return _buildUrl(urlProps, location);
}

export function iframeNavigate(to: string, options?: NavigateOptions) {
  console.log("CHILD", "NAVIGATE", to);
  window.parent.postMessage(
    {
      type: "navigate",
      to,
      options,
      trigger: "navigate",
    },
    "*",
  );
}

export const useNavigate =
  window.top === window.self ? _useNavigate : () => iframeNavigate;

export function useQuery(): Partial<Record<string, string>> {
  const [searchParams] = useSearchParams();

  return useMemo(() => Object.fromEntries(searchParams), [searchParams]);
}

export function useRequiredParam<Key extends string>(key: Key): string {
  const { t } = useTranslation("common");
  const params = useParams();

  const value = params[key];

  if (typeof value !== "string") {
    throw new Error(
      t("services.router-utils.missing-required-par", { val: key }),
    );
  }

  return value;
}

export function useValidateParam<
  Value extends string | undefined,
  Key extends string = string,
>(
  params: Readonly<Params<string>>,
  options: {
    key: Key;
    validate: (value: string | undefined) => value is Value;
  },
): Record<Key, Value> {
  const { t } = useTranslation("common");
  const { key, validate } = options;

  const value = params[key];

  if (!validate(value)) {
    throw new Error(
      t("services.router-utils.invalid-parameter-ke", { val: key }),
    );
  }

  return { [key]: value } as Record<Key, Value>;
}
