import type {
  ApiLookbooksAddItems,
  ApiLookbooksCreate,
  ApiLookbooksDuplicate,
  ApiLookbooksItemsDuplicate,
  ApiLookbooksItemsList,
  ApiLookbooksItemsSelectCover,
  ApiLookbooksItemsUpdate,
  ApiLookbooksItemsUpdatePosition,
  ApiLookbooksItemsUpdateSection,
  ApiLookbooksList,
  ApiLookbooksSectionsCreate,
  ApiLookbooksSectionsDelete,
  ApiLookbooksSectionsDuplicate,
  ApiLookbooksSectionsUpdate,
  ApiLookbooksSectionsUpdatePosition,
  ApiLookbooksToggleIsHidden,
  ApiLookbooksUpdate,
} from "@endearhq/service-schema/lib/Models.schema";
import axios from "axios";

import { RELEASE, DOPPLER_CONFIG } from "../../conf";
import { request } from "./utils";

const api = axios.create({
  baseURL: "/api/lookbooks",
  validateStatus: () => true,
  timeout: 10000,
  headers: {
    "X-Endear-Version": RELEASE,
    "X-Endear-Env": DOPPLER_CONFIG,
    "X-Endear-Application": "web",
  },
});

// Lookbooks

export const apiListLookbooks = async (query: ApiLookbooksList["query"]) =>
  request(async () =>
    api.get<ApiLookbooksList["response"]["200"]>("/list", {
      params: query,
    }),
  );

export const apiAddItems = async (body: ApiLookbooksAddItems["body"]) =>
  request(async () =>
    api.post<ApiLookbooksAddItems["response"]["200"]>("/add_items", body),
  );

export const apiCreateLookbook = async (body: ApiLookbooksCreate["body"]) =>
  request(async () =>
    api.post<ApiLookbooksCreate["response"]["200"]>("/create", body),
  );

export const apiUpdateLookbook = async (body: ApiLookbooksUpdate["body"]) =>
  request(async () =>
    api.post<ApiLookbooksUpdate["response"]["200"]>("/update", body),
  );

export const apiDuplicateLookbooks = async (
  body: ApiLookbooksDuplicate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksDuplicate["response"]["200"]>("/duplicate", body),
  );

export const apiToggleLookbooksIsHidden = async (
  body: ApiLookbooksToggleIsHidden["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksToggleIsHidden["response"]["200"]>(
      "/toggle_is_hidden",
      body,
    ),
  );

// Sections

export const apiCreateLookbookSection = async (
  body: ApiLookbooksSectionsCreate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksSectionsCreate["response"]["200"]>(
      "/sections/create",
      body,
    ),
  );

export const apiUpdateLookbookSection = async (
  body: ApiLookbooksSectionsUpdate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksSectionsUpdate["response"]["200"]>(
      "/sections/update",
      body,
    ),
  );

export const apiDeleteLookbookSection = async (
  body: ApiLookbooksSectionsDelete["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksSectionsDelete["response"]["200"]>(
      "/sections/delete",
      body,
    ),
  );

export const apiDuplicateLookbookSections = async (
  body: ApiLookbooksSectionsDuplicate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksSectionsDuplicate["response"]["200"]>(
      "/sections/duplicate",
      body,
    ),
  );

export const apiUpdateLookbookSectionPosition = async (
  body: ApiLookbooksSectionsUpdatePosition["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksSectionsUpdatePosition["response"]["200"]>(
      "/sections/update_position",
      body,
    ),
  );

// Items

export const apiListLookbookItems = async (
  query: ApiLookbooksItemsList["query"],
) =>
  request(async () =>
    api.get<ApiLookbooksItemsList["response"]["200"]>("/items/list", {
      params: query,
    }),
  );

export const apiUpdateLookbookItem = async (
  body: ApiLookbooksItemsUpdate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksItemsUpdate["response"]["200"]>("/items/update", body),
  );

export const apiUpdateLookbookItemPosition = async (
  body: ApiLookbooksItemsUpdatePosition["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksItemsUpdatePosition["response"]["200"]>(
      "/items/update_position",
      body,
    ),
  );

export const apiUpdateLookbookItemSection = async (
  body: ApiLookbooksItemsUpdateSection["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksItemsUpdateSection["response"]["200"]>(
      "/items/update_section",
      body,
    ),
  );

export const apiSelectCover = async (
  body: ApiLookbooksItemsSelectCover["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksItemsSelectCover["response"]["200"]>(
      "/items/select_cover",
      body,
    ),
  );

export const apiDuplicateLookbookItem = async (
  body: ApiLookbooksItemsDuplicate["body"],
) =>
  request(async () =>
    api.post<ApiLookbooksItemsDuplicate["response"]["200"]>(
      "/items/duplicate",
      body,
    ),
  );
