import type { ApiLookbooksSectionsDelete } from "@endearhq/service-schema/lib/Models.schema";
import type { Observable, Subject } from "rxjs";
import { from, of } from "rxjs";
import { catchError, mergeMap, retryWhen } from "rxjs/operators";

import { apiDeleteLookbookSection } from "../services/api/lookbooks";
import { reportError } from "../services/error-reporter";
import {
  AuthenticationError,
  CodeError,
  ErrorCode,
  isNetworkError,
  toError,
} from "../services/errors";
import { retryOnServiceUnavailable } from "./retryStrategy";
import type { Res } from "./types";
import { errRes, okRes } from "./types";

export interface Payload {
  body: ApiLookbooksSectionsDelete["body"];
}

export type Result = Res<{
  body: ApiLookbooksSectionsDelete["body"];
  response: ApiLookbooksSectionsDelete["response"]["200"];
}>;

export type Name = "deleteLookbookSection";

export const make = (source$: Subject<Payload>): Observable<Result> =>
  source$.pipe(
    mergeMap(({ body }) => of({ body })),
    mergeMap((req) =>
      from(Promise.resolve(req)).pipe(
        mergeMap(async ({ body }) => apiDeleteLookbookSection(body)),
        mergeMap((res): Observable<Result> => {
          switch (res.status) {
            case 200: {
              return of(okRes({ body: req.body, response: res.data }));
            }
            case 401: {
              throw new AuthenticationError(res);
            }
            case 403: {
              throw new CodeError({
                code: ErrorCode.PermissionDenied,
                payload: res,
              });
            }
            default: {
              throw new CodeError({
                code: ErrorCode.UnexpectedStatus,
                payload: res,
              });
            }
          }
        }),
        retryWhen(retryOnServiceUnavailable()),
        catchError((err) => {
          const error = isNetworkError(err)
            ? new CodeError({ code: ErrorCode.Offline })
            : toError(err);

          reportError(error);

          return of(errRes(error));
        }),
      ),
    ),
  );
