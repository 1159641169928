import type { AppError } from "../services/errors";

export interface OkRes<Val> {
  ok: true;
  val: Val;
}

export interface ErrRes {
  ok: false;
  err: AppError;
}

export type Res<Val> = OkRes<Val> | ErrRes;

export const okRes = <Val>(val: Val): OkRes<Val> => ({ ok: true, val });

export const errRes = (err: AppError): ErrRes => ({ ok: false, err });
