import type { ApiOrdersList } from "@endearhq/service-schema/lib/Models.schema";
import axios from "axios";

import { RELEASE, DOPPLER_CONFIG } from "../../conf";
import { request } from "./utils";

const api = axios.create({
  baseURL: "/api/orders",
  validateStatus: () => true,
  timeout: 10000,
  headers: {
    "X-Endear-Version": RELEASE,
    "X-Endear-Env": DOPPLER_CONFIG,
    "X-Endear-Application": "web",
  },
});

export const apiListOrders = async (query: ApiOrdersList["query"]) =>
  request(async () =>
    api.get<ApiOrdersList["response"]["200"]>("/list", {
      params: query,
    }),
  );
