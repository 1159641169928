import type { ApiAccountsSenderDomainsCreate } from "@endearhq/service-schema/lib/Models.schema";
import axios from "axios";

import { RELEASE, DOPPLER_CONFIG } from "../../conf";
import { request } from "./utils";

const api = axios.create({
  baseURL: "/api/accounts",
  validateStatus: () => true,
  timeout: 10000,
  headers: {
    "X-Endear-Version": RELEASE,
    "X-Endear-Env": DOPPLER_CONFIG,
    "X-Endear-Application": "web",
  },
});

export const apiCreateSenderDomain = async (
  body: ApiAccountsSenderDomainsCreate["body"],
) =>
  request(async () =>
    api.post<ApiAccountsSenderDomainsCreate["response"]["200"]>(
      "/sender_domains/create",
      body,
    ),
  );
